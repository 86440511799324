<template>
  <div class="input-block">
    <textarea
      ref="textarea"
      v-model="comment"
      v-focus="autofocus"
      :maxlength="checkValues('transactionComment', 'max')"
      class="input-text fs-small"
      :class="{
        'input-text-correct': commentValid,
        'input-text-error': !commentValid}"

      @input="inputComment()"
    />

    <div
      class="input-counter input-counter-bottom"
      :class="{
        'color-red': !commentValid, 
        'input-counter-correct': commentValid}"
    >
      {{ inputLengthString('transactionComment', comment) }}
    </div>
  </div>
</template>

<script>
import checkInputValueMixin from '@/mixins/checkInputValue.mixin'
import inputLengthStringMixin from '@/mixins/inputLengthString.mixin'
import resizeTextArea from '@/mixins/resizeTextArea.mixin'

export default {
  name: 'TransactionCommentComponent',
  mixins: [checkInputValueMixin, inputLengthStringMixin, resizeTextArea],
  props: {
    setCommentText: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    comment: ''
  }),
  computed: {
    commentValid() {
      if (this.comment.length <= this.checkValues('transactionComment', 'max')) { return true }
      return false
    }
  },
  created() {
    if (this.setCommentText) {
      this.comment = this.setCommentText
    }
  },
  async mounted() {
    this.emitComment()
  },
  methods: {
    async inputComment() {
      await this.resizeTextArea()

      this.emitComment()
    },
    emitComment () {
      this.$emit('comment-text-input', this.comment)
    }
  }
}
</script>