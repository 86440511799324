<template>
  <div class="input-sum-block">
    <button
      v-if="+operationSum && (+operationSum < 0 || +operationSum > 0)"
      class="input-sum-button width_1 overflow-dots fs-large"
      :class="{
        'color-red': +operationSum < 0,
        'color-green': +operationSum > 0,
        'cursor-pointer': sumValid,
        'cursor-default': !sumValid
      }"
      @click="changeMinus()"
    >
      <span v-if="+operationSum < 0">-</span>
      <span v-else-if="+operationSum > 0">+</span>
    </button>
    
    <input
      v-model.trim="operationSum"
      v-focus="setAutofocus"
      name="operationSum"
      class="input-sum fs-small"
      autocomplete="off"
      @keydown.tab.stop="prepareSum()"
      @keydown.enter.prevent.stop="prepareSum()"
      @keydown.esc="hide"
      @input="onSumInput()"
      @blur="sumBlured()"
    >
    
    <div class="width_2_5 flex-center vertical_align_center">
      <CloseRedButton
        v-if="showClearButton && operationSum && ((operationSum + '').indexOf('=') < 0)"
        @clicked="closeButtonClicked()"
      />

      <button
        v-if="(operationSum + '').indexOf('=') === 0 && (operationSum + '').length > 1 && canEvaluate()"
        v-tooltip="{
          text: $filters.localizeFilter('Count'),
          padding: 4
        }"
        class="input-sum-equality-button overflow-dots cursor-pointer color-main button-grey-second fs-large"
        @click.prevent.stop="countExpression()"
      >
        =         
      </button>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { evaluate } from 'mathjs'
import { logAnalyticsEvent } from '@/firebase/analytics'

export default {
  name: 'TransactionSumComponent',
  components: {
    CloseRedButton: defineAsyncComponent(() => import('@/components/UI/buttons/CloseRedButton'))
  },
  props: {
    setSum: {
      type: Number,
      default: null
    },
    setAutofocus: {
      type: Boolean,
      default: true
    },
    showClearButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    operationSum: null,
    sum: 0
  }),
  computed: {
    sumValid() {
      if (!(+this.operationSum)) { return false }
      if (isNaN(+this.operationSum)) { return false }
      return true
    }
  },
  watch: {
    operationSum() {
      this.$emit('chosen-new-sum', this.operationSum)
    },
    setSum() {
      if (this.setSum !== null) {
        this.sum = +this.setSum
        this.operationSum = (+(+this.sum / 100).toFixed(2))
        this.onSumInput()
      } else {
        this.sum = 0
        this.operationSum = null
      }
    }
  },
  created() {
    if (this.setSum !== null) {
      this.sum = +this.setSum
      this.operationSum = (+(+this.sum / 100).toFixed(2))
      this.onSumInput()
    } else {
      this.sum = 0
      this.operationSum = null
    }
  },
  methods: {
    canEvaluate() {
      let str = String(this.operationSum)
      str = str.replace(/=/gi, '')
      str = str.replace(/,/gi, '.')

      try {
        evaluate(str)
        return true
      } catch (e) {
        return false
      }
    },
    async prepareSum() {
      await this.countExpression()

      if (!this.sumValid) { return }

      if (+this.operationSum !== 0) {
        this.operationSum = +((Math.round(+this.operationSum * 100) / 100).toFixed(2))
      }

      if (Math.round(+this.operationSum * 100) === +this.sum) { return }
    },
    changeMinus() {
      if (!this.sumValid) { return }

      let sum = ((Math.round(+this.operationSum * -100)) / 100).toFixed(2)
      this.operationSum = +sum
    },
    async countExpression () {
      if (this.operationSum === null) { return }

      let str = String(this.operationSum)

      const canEvaluate = await this.canEvaluate()

      if (!canEvaluate) { return }

      str = str.replace(/=/gi, '')
      str = str.replace(/,/gi, '.')

      this.operationSum = +(Math.round(+evaluate(str) * 100) / 100).toFixed(2)
      this.onSumInput()

      logAnalyticsEvent('changeMinusClicked')
    },
    onSumInput() {
      if (this.operationSum === '-') { return }
      
      if (!this.operationSum) { 
        this.operationSum = 0
        return 
      }

      let str = String(this.operationSum)
      if (str === '-0') { return }
      const lastSymbol = str.slice(-1)
      const symb = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',', '-']
      const symbols = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',', '+', '-', '*', '/', '=', '(', ')']

      const isExpression = str.indexOf('=') === 0

      if (isExpression) {
        if (str.indexOf('=') > 0) {
          this.operationSum = str.slice(0, str.length - 1)
          return
        }

        if (symbols.indexOf(lastSymbol) < 0) {
          this.operationSum = str.slice(0, str.length - 1)
          return
        }

        return
      } else {
        if (symb.indexOf(lastSymbol) < 0) {
          str = str.slice(0, str.length - 1)
        }

        if (lastSymbol === '.' || lastSymbol === ',') { return }
      }

      const lastTwoSymbols = str.slice(str.length - 2)
      if (lastTwoSymbols === '.0' || lastTwoSymbols === ',0') {
        return
      }

      str = str.replace(/,/gi, '.')

      if (isNaN(+str)) { return }

      this.operationSum = +str

      let maxLength = 10
      if (+this.operationSum < 0) { maxLength = 11 }
      if (str.length > maxLength) { this.operationSum = +str.slice(0, maxLength) }
      if (!(this.operationSum + '').length) { this.operationSum = null }

      if (this.operationSum !== null) {
        this.operationSum = +((Math.round(+this.operationSum * 100) / 100).toFixed(2))
      }
    },
    sumBlured () {
      const str = String(this.operationSum)
      if (str.indexOf('=') === 0) { return }
      this.countExpression()
    },
    closeButtonClicked() {
      this.$emit('chosen-new-sum', null)
    }
  }
}
</script>
